import React from "react";
import { PiChartLineUpLight, PiChartLineDownLight } from "react-icons/pi";
import { GoDash } from "react-icons/go";
import { EMPTY_VALUE } from "./constants.js";

export const getStyle = (diff) => {
  let style = {
    sign: "",
    bgColorClass: "",
    textColorClass: "",
    borderColorClass: "",
    icon: <GoDash className="text-5xl text-blue-600" />,
  };

  if (diff === EMPTY_VALUE || diff === 0) {
    style = {
      ...style,
      bgColorClass: "bg-blue-50",
      textColorClass: "text-blue-700",
      borderColorClass: "border-blue-500",
    };
  } else if (diff > 0) {
    style = {
      sign: "+",
      bgColorClass: "bg-red-50",
      textColorClass: "text-red-700",
      borderColorClass: "border-red-500",
      icon: <PiChartLineUpLight className="text-5xl text-red-600" />,
    };
  } else if (diff < 0) {
    style = {
      sign: "",
      bgColorClass: "bg-green-50",
      textColorClass: "text-green-700",
      borderColorClass: "border-green-500",
      icon: <PiChartLineDownLight className="text-5xl text-green-600" />,
    };
  }

  return style;
};
