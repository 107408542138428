import React from "react";
import { CURRENCY, EMPTY_VALUE } from "../../utils/constants.js";
import { useStatisticsSectionContext } from "../../context/StatisticsSectionContext";
import { useTranslation } from "react-i18next";

export default function AveragePricesTable() {
  const { data } = useStatisticsSectionContext();
  const { t } = useTranslation();

  const getAverages = (data) => {
    return data?.map((fuel) => {
      const values = fuel.measuresArray
        .map((m) => m.value)
        .filter((v) => typeof v === "number" && !isNaN(v));
      const average =
        values.reduce((sum, val) => sum + val, 0) / (values.length || 1);
      return {
        name: fuel.name,
        average: isNaN(average) ? null : average.toFixed(3),
      };
    });
  };

  const averages = getAverages(data);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse table-auto">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="px-4 py-2 text-left">{t("common.fuelName")}</th>
            <th className="px-4 py-2 text-left">{t("common.price")}</th>
          </tr>
        </thead>
        <tbody>
          {averages?.map((fuel, index) => (
            <tr
              key={index}
              className={`border-t hover:bg-gray-100 ${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }`}
            >
              <td className="px-4 py-2">{fuel.name}</td>
              <td className="px-4 py-2">
                {fuel.average !== null ? fuel.average + CURRENCY : EMPTY_VALUE}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
