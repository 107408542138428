import React from "react";
import { useForm } from "react-hook-form";
import { useYearsComparisionContext } from "../../context/YearsComparisionContext";
import { t } from "i18next";

import { CURRENT_YEAR } from "../../utils/constants.js";
import Error from "../layout/Error.jsx";
import { sanitizeFuel, sanitizeYear } from "../../utils/sanitize-functions.js";

export default function ComparisionFilter() {
  const {
    apiData,
    paramsState,
    setParamsState,

    error,
  } = useYearsComparisionContext();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstYear: paramsState.firstYear,
      secondYear: paramsState.secondYear,
      fuel: paramsState.fuel,
    },
  });

  const data = apiData.secondYearApiData;

  const onSubmit = (formData) => {
    const updatedParams = {
      ...paramsState,
      firstYear: sanitizeYear(formData.firstYear),
      secondYear: sanitizeYear(formData.secondYear),
      fuel: sanitizeFuel(formData.fuel),
    };
    setParamsState(updatedParams);
  };

  return (
    <div className="max-w-full ">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="max-w-full bg-white shadow-lg rounded-lg p-6"
      >
        {/* Fuel Selection */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          <h2 className="text-lg font-semibold text-gray-700 mb-3">
            {t("common.selectFuel")}
          </h2>

          {/* Responsive Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3">
            {data?.map((fuel, index) => (
              <label
                key={fuel.name}
                className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-300 bg-white cursor-pointer transition-all duration-300 
                 hover:bg-gray-200 peer-checked:bg-green-500 peer-checked:text-white"
              >
                <input
                  type="radio"
                  {...register("fuel")}
                  value={index}
                  defaultChecked={index === paramsState.fuel}
                />
                <span className="font-medium text-center">{fuel.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Year Selection */}
        <div className="flex flex-col md:flex-row items-center gap-4 bg-gray-100 p-4 rounded-lg shadow-inner">
          <label className="text-gray-700 font-semibold text-lg">
            {t("common.selectYear")}
          </label>
          <div className="flex gap-3 w-full">
            <input
              type="number"
              min="2000"
              max={CURRENT_YEAR - 1}
              {...register("secondYear", { required: true })}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500 w-full sm:w-32"
            />
            <input
              type="number"
              min="2000"
              max={CURRENT_YEAR}
              {...register("firstYear", { required: true })}
              className="px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500 w-full sm:w-32"
            />
          </div>
        </div>

        {/* Week Interval & Submit Button */}
        <div className="flex flex-col md:flex-row justify-between items-center bg-gray-100 p-4 rounded-lg shadow-inner">
          <button
            type="submit"
            className="mt-3 md:mt-0 bg-green-600 text-white px-6 py-3 rounded-lg font-semibold shadow-md hover:bg-green-700 transition"
          >
            {t("common.show")}
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="text-red-500 font-medium bg-red-100 p-3 rounded-md shadow">
            <Error messageKey={t("common.notFound")} />
          </div>
        )}
      </form>
    </div>
  );
}
