import { EMPTY_VALUE, MIN_FUEL, MAX_FUEL } from "./constants";

export const filterFuelsByType = (fuelsToKeep, newData) => {
  return newData?.filter((_, index) => fuelsToKeep.includes(index));
};

export const filterMeasuresByInterval = (start, end, newData) => {
  const filteredData = newData.map((fuel) => {
    const filteredMeasures = fuel?.measuresArray?.filter((weekObj) => {
      const weekNumber = parseInt(weekObj.week?.split(".")[0], 10); // Extract week number
      return weekNumber >= start && weekNumber <= end;
    });
    return {
      ...fuel,
      measuresArray: filteredMeasures,
    };
  });
  return filteredData;
};

export const processApiData = (response) => {
  if (response[0]?.name && response[0]?.measuresArray) return response;
  try {
    const fuelObjectsArray = [];
    const fuelsArray = Object.values(
      response.dimension.sp0207ts_ukaz.category.label
    );
    const numberOfFuels = fuelsArray.length;
    const numberOfMeasures = response.value.length;

    const weeksArray = Object.values(
      response.dimension.sp0207ts_tyz.category.label
    ).reverse();

    fuelsArray.forEach((fuel, index1) => {
      const fuelMeasures = [];
      let indexOfWeek = 0;
      for (
        let index2 = index1;
        index2 < numberOfMeasures;
        index2 = index2 + numberOfFuels
      ) {
        fuelMeasures.push({
          week: weeksArray[indexOfWeek],
          value: response.value[index2] ? response.value[index2] : EMPTY_VALUE,
        });
        indexOfWeek++;
      }
      fuelMeasures.reverse();
      fuelObjectsArray.push({
        name: fuel,
        measuresArray: fuelMeasures,
      });
    });

    return fuelObjectsArray.map((fuel) => ({
      ...fuel,
      measuresArray: fuel.measuresArray.filter(
        (measure) => measure.value !== "-"
      ),
    }));
  } catch (err) {
    return null;
  }
};

export const getFuelWithMaxOrMinValue = (fuelArray, type) => {
  if (type !== "max" && type !== "min") {
    throw new Error("Invalid input: type must be 'max' or 'min'");
  }

  return fuelArray?.map((fuel) => {
    const validMeasures = fuel.measuresArray.filter(
      (measure) => typeof measure.value === "number"
    );

    if (validMeasures.length === 0) {
      return { name: fuel.name, value: null, week: null };
    }

    const desiredMeasure = validMeasures.reduce((acc, current) => {
      if (type === "max") {
        return current.value > acc.value ? current : acc;
      } else {
        return current.value < acc.value ? current : acc;
      }
    }, validMeasures[0]);

    return {
      name: fuel.name,
      value: desiredMeasure.value,
      week: desiredMeasure.week,
    };
  });
};

export const processDataForTable = (data) => {
  let weekMeasuresArray = [];
  const measuresArray = data?.map((fuel) =>
    fuel.measuresArray?.map((measure) => measure.value)
  );

  for (let week = 0; week < data.length; week++) {
    const weekArray = [];
    for (
      let fuelType = 0;
      fuelType < data[0].measuresArray.length;
      fuelType++
    ) {
      weekArray.push(measuresArray[week][fuelType]);
    }
    weekMeasuresArray.push(weekArray);
  }

  return weekMeasuresArray[0]?.map((_, colIndex) =>
    weekMeasuresArray?.map((row) => row[colIndex])
  );
};

export const clampFuel = (fuel) => {
  const parsedFuel = parseInt(fuel, 10);
  if (isNaN(parsedFuel)) return "0"; // Default to "0" if not a valid number
  return Math.min(MAX_FUEL, Math.max(MIN_FUEL, parsedFuel)).toString();
};
