import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  sanitizeFuels,
  sanitizeWeek,
  sanitizeYear,
} from "../utils/sanitize-functions.js";

export const useStatisticsParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramsState, setParamsState] = useState({
    year: sanitizeYear(searchParams.get("year")),
    start: sanitizeWeek(searchParams.get("start"), searchParams.get("year")),
    end: sanitizeWeek(searchParams.get("end"), searchParams.get("year"), true),
    fuels: sanitizeFuels(searchParams.get("fuels")),
  });

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set("year", sanitizeYear(paramsState.year));
    newParams.set("start", sanitizeWeek(paramsState.start, paramsState.year));
    newParams.set("end", sanitizeWeek(paramsState.end, paramsState.year, true));
    newParams.set("fuels", sanitizeFuels(paramsState.fuels));

    setSearchParams(newParams);
  }, [paramsState, setSearchParams]);

  return { paramsState, setParamsState };
};
