import {
  MIN_WEEK,
  CURRENT_YEAR,
  INITIAL_FUEL_DISPLAY_COUNT,
  MIN_FUEL,
  MAX_FUEL,
} from "../utils/constants.js";
import { getCurrentWeekNumber } from "../utils/week-functions.js";

/**
 * Ensures the year is a valid integer within a reasonable range.
 * @param {string | number} year
 * @returns {number} A valid year (between 2000 and CURRENT_YEAR)
 */
export const sanitizeYear = (year) => {
  const parsed = parseInt(year, 10);

  return !isNaN(parsed) && parsed >= 2000 && parsed <= CURRENT_YEAR
    ? parsed.toString()
    : CURRENT_YEAR;
};

/**
 * Ensures the week number is within valid limits.
 * @param {string | number} week
 * @param {boolean} isEnd - If true, it sets the week to max possible if invalid.
 * @returns {number} A valid week number.
 */
export const sanitizeWeek = (week, year, isEnd = false) => {
  const parsed = parseInt(week, 10);
  const maxWeek = getCurrentWeekNumber(year);
  if (isNaN(parsed) || parsed < MIN_WEEK || parsed > maxWeek) {
    return isEnd ? maxWeek : MIN_WEEK;
  }
  return parsed;
};

/**
 * Ensures fuel value is a valid number between MIN_FUEL and MAX_FUEL.
 * @param {string | number} fuel
 * @returns {number} A valid fuel value.
 */
export const sanitizeFuel = (fuel) => {
  const parsed = parseInt(fuel, 10);
  return !isNaN(parsed) && parsed >= MIN_FUEL && parsed <= MAX_FUEL
    ? parsed
    : MIN_FUEL;
};

/**
 * Ensures the fuels array contains valid integers within range and limits count.
 * @param {string | null} fuels
 * @returns {number[]} An array of valid fuel indices.
 */
export const sanitizeFuels = (fuels) => {
  if (!fuels)
    return Array.from(
      { length: INITIAL_FUEL_DISPLAY_COUNT },
      (_, index) => index
    );

  const fuelArray = typeof fuels !== "string" ? fuels : fuels.split(",");
  return fuelArray
    .map((fuel) => parseInt(fuel, 10))
    .filter((fuel) => !isNaN(fuel) && fuel >= MIN_FUEL && fuel <= MAX_FUEL);
};
