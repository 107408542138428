import React from "react";

export default function CustomList({ content, CustomComponent }) {
  return (
    <ul className="max-w-full list-disc marker:text-[rgba(41,122,73,0.75)] text-xl">
      {content?.map((block, index) => {
        return (
          <li className="py-6 text-m" key={block.header.length + index}>
            <div className="border rounded-lg px-2 my-2 mb-4 max-w-max bg-[rgba(41,122,73,0.75)]">
              <h1 className="text-xl regular">{block.header}</h1>
            </div>
            <span className="text-base font-light tracking-tight">{block.text}</span>
          </li>
        );
      })}
      {CustomComponent && <CustomComponent />}
    </ul>
  );
}
