import { useYearsComparisionContext } from "../../context/YearsComparisionContext";
import React, { useEffect } from "react";
import Spinner from "../layout/Spinner";
import BarGraph from "../graphs/BarGraph";
import ComparisionFilter from "../graphs/ComparisionFilter";
import PriceDevelopmentGraph from "../graphs/PriceDevelopmentGraph";
import InformationAboutData from "../graphs/InformationAboutData";
import { useTranslation } from "react-i18next";
import { CiMinimize1, CiMaximize1 } from "react-icons/ci";
import { useModal } from "../../hooks/useModal";

export default function YearsComparision() {
  const { paramsState, loading, data, error } = useYearsComparisionContext();
  const { modalElement, modalRef, toggleModal } = useModal();

  useEffect(() => {
    toggleModal(paramsState.zoomed);
  }, []);

  const { t } = useTranslation();
  const headerStyle = "font-bold text-lg tracking-wide";

  const modalClasses = (id) =>
    modalElement === id
      ? "fixed inset-0 z-50 bg-white shadow-lg p-4 overflow-y-auto min-h-[100vh] max-h-[100vh] min-w-[100vw] max-w-[100vw]"
      : "rounded-lg shadow-md overflow-hidden";

  const graphsCustomTitle = `${data[0]?.name} ${t(
    "comparision.comparisionBetween"
  )} ${paramsState.firstYear} ${t("common.and")} ${paramsState.secondYear} (${t(
    "common.slovakRepublic"
  )})`;

  const components = [
    {
      id: "fuel-selection-heading",
      title: t("statistics.checkboxHeader"),
      style: "max-h-fit overflow-y-auto",
      element: <ComparisionFilter />,
    },
    {
      id: "price-development-graph",
      title: t("statistics.priceDevelopmentGraph"),
      style:
        "flex w-full max-w-[100vw] md:max-w-[80vw] h-[70vh] justify-center",
      element: (
        <PriceDevelopmentGraph
          data={data}
          paramsState={paramsState}
          customTitle={graphsCustomTitle}
          specialLabels={[paramsState?.firstYear, paramsState?.secondYear]}
        />
      ),
    },
    {
      id: "bar-graph",
      title: t("statistics.barGraph"),
      style:
        "flex w-full max-w-[100vw] md:max-w-[80vw]  h-[70vh] justify-center",
      element: (
        <BarGraph
          data={data}
          paramsState={paramsState}
          customTitle={graphsCustomTitle}
          specialLabels={[paramsState?.firstYear, paramsState?.secondYear]}
        />
      ),
    },
    {
      id: "informations",
      title: t("informationAboutData.title"),
      style: "max-[50vh] overflow-y-auto",
      element: <InformationAboutData />,
    },
  ];

  return (
    <>
      <title>Porovnanie cien palív | Štatistiky palív na Slovensku</title>
      <main className="m-2 gap-4 flex flex-col items-center w-full">
        {components.map(({ id, title, element, style }) => {
          if (error && id !== "fuel-selection-heading") return null;
          return (
            <div
              key={id}
              className={`${modalClasses(
                id
              )} ${style} flex flex-col items-center`}
              ref={modalElement === id ? modalRef : null}
              aria-labelledby={`${id}-heading`}
            >
              <header className="sticky top-0 bg-neutral-100 flex justify-between items-center w-full px-4">
                <h2
                  className={`${headerStyle} text-base md:text-lg`}
                  id={`${id}-heading`}
                >
                  {title}
                </h2>
                {modalElement === id ? (
                  <CiMinimize1
                    className="cursor-pointer text-4xl mx-3"
                    onClick={() => toggleModal(id)}
                  />
                ) : (
                  <CiMaximize1
                    className="cursor-pointer text-2xl mx-3"
                    onClick={() => toggleModal(id)}
                  />
                )}
              </header>
              {loading ? <Spinner /> : element}
            </div>
          );
        })}
      </main>
    </>
  );
}
