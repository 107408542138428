export const graphColors = [
    {
      borderColor: "rgba(255, 99, 132, 1)",
      backgroundColor: "rgba(255, 99, 132, 0.8)",
    }, // Red
    {
      borderColor: "rgba(54, 162, 235, 1)",
      backgroundColor: "rgba(54, 162, 235, 0.8)",
    }, // Blue
    {
      borderColor: "rgba(75, 192, 192, 1)",
      backgroundColor: "rgba(75, 192, 192, 0.8)",
    }, // Teal
    {
      borderColor: "rgba(255, 206, 86, 1)",
      backgroundColor: "rgba(255, 206, 86, 0.8)",
    }, // Yellow
    {
      borderColor: "rgba(153, 102, 255, 1)",
      backgroundColor: "rgba(153, 102, 255, 0.8)",
    }, // Purple
    {
      borderColor: "rgba(255, 159, 64, 1)",
      backgroundColor: "rgba(255, 159, 64, 0.8)",
    }, // Orange
    {
      borderColor: "rgba(99, 255, 132, 1)",
      backgroundColor: "rgba(99, 255, 132, 0.8)",
    }, // Light Green
    {
      borderColor: "rgba(201, 203, 207, 1)",
      backgroundColor: "rgba(201, 203, 207, 0.8)",
    }, // Grey
    {
      borderColor: "rgba(255, 105, 180, 1)",
      backgroundColor: "rgba(255, 105, 180, 0.8)",
    }, // Hot Pink
    {
      borderColor: "rgba(255, 165, 0, 1)",
      backgroundColor: "rgba(255, 165, 0, 0.8)",
    }, // Gold
    {
      borderColor: "rgba(0, 255, 127, 1)",
      backgroundColor: "rgba(0, 255, 127, 0.8)",
    }, // Spring Green
  ];