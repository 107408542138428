import React from "react";
import { EMPTY_VALUE, ROUND_DIGITS } from "../../utils/constants.js";

export default function PercentageComponent(
  styleObj,
  priceDifference,
  firstWeek,
  lastWeek,
  displayedCurrency
) {
  function isNumber(value) {
    if (Number.isNaN(value) || !Number.isFinite(value)) return false;
    return typeof value === "number";
  }
  if (priceDifference === 0 && priceDifference !== EMPTY_VALUE) return;

  const percentage = (lastWeek.value / firstWeek.value - 1) * 100;
  const percentageChange = isNumber(percentage)
    ? percentage.toFixed(ROUND_DIGITS) + "%"
    : "";
  return (
    <div className={`text-sm top-0 ${styleObj.textColorClass}`}>
      {`${styleObj.sign}${priceDifference}${displayedCurrency} (${percentageChange})`}
    </div>
  );
}
