import React from "react";
import { useTranslation } from "react-i18next";

export default function InformationAboutData() {
  const { t } = useTranslation();

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* Functions */}
      <section className="mb-4">
        <h3 className="text-xl font-medium">
          {t("informationAboutData.functionality")}
        </h3>
        <p>{t("informationAboutData.functionality_text")}</p>
      </section>
      {/* Data Collection */}
      <section className="mb-4">
        <h3 className="text-xl font-medium">
          {t("informationAboutData.data_collection.title")}
        </h3>
        <p>{t("informationAboutData.data_collection.description")}</p>
      </section>

      {/* Fuel Types */}
      <section className="mb-4">
        <h3 className="text-xl font-medium">
          {t("informationAboutData.fuel_types.title")}
        </h3>
        <ul className="list-disc pl-6">
          <li>{t("informationAboutData.fuel_types.LPG")}</li>
          <li>{t("informationAboutData.fuel_types.CNG")}</li>
          <li>{t("informationAboutData.fuel_types.LNG")}</li>
          <li>{t("informationAboutData.fuel_types.bioLNG")}</li>
          <li>{t("informationAboutData.fuel_types.Hydrogen")}</li>
          <li>
            <strong>
              {t("informationAboutData.fuel_types.Electricity.title")}
            </strong>
            <ul className="list-disc pl-6">
              <li>{t("informationAboutData.fuel_types.Electricity.AC")}</li>
              <li>{t("informationAboutData.fuel_types.Electricity.DC")}</li>
              <li>{t("informationAboutData.fuel_types.Electricity.Ultra")}</li>
            </ul>
          </li>
        </ul>
      </section>

      {/* Data Sources */}
      <section className="mb-4">
        <h3 className="text-xl font-medium">
          {t("informationAboutData.data_sources.title")}
        </h3>
        <p>{t("informationAboutData.data_sources.description")}</p>
      </section>

      {/* Source */}
      <footer className="text-sm text-gray-600 mt-4">
        <p>{t("informationAboutData.source")}</p>
      </footer>
    </div>
  );
}
