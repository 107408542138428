import React from "react";
import { EMPTY_VALUE, CURRENCY, ROUND_DIGITS } from "../../utils/constants.js";
import { useStatisticsSectionContext } from "../../context/StatisticsSectionContext";
import Spinner from "../layout/Spinner.jsx";
import PercentageComponent from "./PercentageComponent";
import {
  getWeekIntervalStr,
  getFirstAndLastWeeks,
} from "../../utils/week-functions.js";
import { getStyle } from "../../utils/style-functions.js";

export default function CurrentWeek() {
  const { data, loading } = useStatisticsSectionContext();
  if (loading) return <Spinner />;
  let date;

  return (
    <div className="flex flex-row overflow-auto snap-x min-w-full py-2">
      {data?.map((fuel) => {
        const { lastWeek, firstWeek } = getFirstAndLastWeeks(
          fuel.measuresArray
        );
        date = date ?? getWeekIntervalStr(firstWeek.week, lastWeek.week);
        let diff;
        if (firstWeek.value !== EMPTY_VALUE) {
          diff = lastWeek.value.toString() - firstWeek.value.toString();
        } else {
          diff = EMPTY_VALUE;
        }
        const styleObj = getStyle(diff);
        const priceDifference =
          diff !== EMPTY_VALUE ? diff.toFixed(ROUND_DIGITS) : 0;

        const displayedCurrency = diff !== EMPTY_VALUE ? CURRENCY : "";
        let percentageComponent = PercentageComponent(
          styleObj,
          priceDifference,
          firstWeek,
          lastWeek,
          displayedCurrency
        );

        return (
          <div
            key={fuel.name}
            className={`relative grid grid grid-rows-[h-[15%],h-[20%],h-[40%] sm:p-4 m-1 min-w-[250px] 
                        rounded-xl shadow-xl transition-transform hover:scale-[1.025] bg-white/70 
                        backdrop-blur-md border-l-4 ${styleObj.borderColorClass}
                        shadow-[4px_4px_16px_rgba(0,0,0,0.1),_0_0_0_1px_rgba(0,0,0,0.05)]`}
            aria-label={`${firstWeek.week.split(".")[0]}-${
              lastWeek.week.split(".")[0]
            }`}
          >
            {/* Date range */}
            <div className="row-1 col-span-2 text-s font-medium text-gray-500 mb-1">
              {date}
            </div>

            {/* Fuel name */}
            <div className="row-2 col-span-2 text-lg font-bold text-gray-800 mb-2">
              {fuel.name.split("(")[0]}
            </div>

            {/* Price and percentage */}
            <div className="row-3 row-span-2 col-span-1 items-center justify-between mb-1">
              <div className="text-3xl font-semibold text-gray-900">
                {lastWeek.value}
                {displayedCurrency}
              </div>
              <div className={`text-sm ${styleObj.textColorClass}`}>
                {percentageComponent}
              </div>
            </div>

            {/* Icon bottom right */}
            <div className="row-span-2 col-span-1 bottom-2 right-2 opacity-80">
              {styleObj.icon}
            </div>
          </div>
        );
      })}
    </div>
  );
}
