import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { sanitizeYear, sanitizeFuel } from "../utils/sanitize-functions.js";
import { CURRENT_YEAR } from "../utils/constants.js";

export const useYearsComparisonParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();


  const [paramsState, setParamsState] = useState({
    firstYear: sanitizeYear(searchParams.get("firstYear") || CURRENT_YEAR),
    secondYear: sanitizeYear(
      searchParams.get("secondYear") || CURRENT_YEAR - 1
    ),
    fuel: sanitizeFuel(searchParams.get("fuel") || "0"),
  });

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set("firstYear", sanitizeYear(paramsState.firstYear));
    newParams.set("secondYear", sanitizeYear(paramsState.secondYear));
    newParams.set("fuel", sanitizeFuel(paramsState.fuel));

    setSearchParams(newParams);
  }, [paramsState, setSearchParams]);

  const setLimitedParamsState = (newParams) => {
    setParamsState((prev) => ({
      ...prev,
      ...newParams,
      firstYear: sanitizeYear(newParams.firstYear),
      secondYear: sanitizeYear(newParams.secondYear),
      fuel: sanitizeFuel(newParams.fuel),
    }));
  };

  return { paramsState, setParamsState: setLimitedParamsState };
};
