import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { FaFacebookSquare, FaInstagram } from "react-icons/fa";

export default function FooterSection() {
  const { t } = useTranslation();
  return (
    <footer className="text-center lg:text-left bg-[rgba(41,122,73,0.1)] md:block pb-2">
      <div className="flex flex-col space-y-1 mx-6 pt-10 justify-center items-center text-center ">
        {/* Content here */}
        <div className="flex sm:flex-row flex-col sm:gap-8 my-6 overflow-auto max-h-full ">
          {/* <!-- Products section --> */}
          <NavLink to="statistiky">
            <h6 className="mb-4 flex text-xl justify-center font-regular  md:justify-start truncate">
              {t("navigation.statistics")}
            </h6>
          </NavLink>
          <NavLink to="oprojekte">
            <h6 className="mb-4 flex text-xl justify-center font-regular  md:justify-start truncate">
              {t("navigation.aboutProject")}
            </h6>
          </NavLink>
          <NavLink to="rocneporovnanie">
            <h6 className="mb-4 flex text-xl justify-center font-regular  md:justify-start truncate">
              {t("navigation.compareYears")}
            </h6>
          </NavLink>
        </div>
        <div className="flex justify-center pb-6">
          <a
            href="https://www.facebook.com/profile.php?id=61565221203598"
            target="_blank"
            rel="noreferrer"
            className="mr-6 text-neutral-600 dark:text-neutral-200"
          >
            <FaFacebookSquare className="h-8 w-8 text-black " />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/palivoslovensko.sk/"
            rel="noreferrer"
            className="mr-6 text-neutral-600 dark:text-neutral-200"
          >
            <FaInstagram className="h-8 w-8 text-black" />
          </a>
        </div>

        <div className="flex mt-auto text-sm">
          <address className="not-italic">
            {t("navigation.contact")}: palivoslovensko@gmail.com
          </address>
        </div>
        <div className="flex mt-auto text-sm">
          {t("sections.dataSource")} www.statistics.sk
        </div>
      </div>
    </footer>
  );
}
