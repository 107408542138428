import React from "react";
import { useTranslation } from "react-i18next";
import { FaReact, FaGithub, FaPython } from "react-icons/fa"; // Font Awesome
import { SiFirebase, SiTailwindcss } from "react-icons/si"; // Simple Icons
import CustomList from "../layout/CustomList";

export default function AboutProject() {
  const { t } = useTranslation();
  const aboutProjectContent = [
    {
      header: t("aboutProject.goalsHeader"),
      text: t("aboutProject.goalsDescription"),
    },
    {
      header: t("aboutProject.technologiesHeader"),
      text: t("aboutProject.technologiesDescription"),
    },
  ];

  const supportUsContent = [
    {
      header: t("supportUs.supportUsHeader"),
      text: t("supportUs.supportUsText"),
    },
  ];
  const furtherGoals = {
    header: t("aboutProject.furtherGoalsHeader"),
    text: t("aboutProject.furtherGoalsDescription"),
  };

  const Icons = () => {
    return (
      <div className="flex flex-wrap justify-center md:space-x-8 text-6xl">
        <title>O projekte | Štatistiky palív na Slovensku</title>
        <meta
          name="description"
          content="Zistite viac o projekte Štatistiky palív na Slovensku, našej misii poskytovať presné údaje a tíme, ktorý stojí za prehľadmi."
        />

        {/* React */}
        <a
          href="https://reactjs.org/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="React Website"
          className="flex flex-col mt-5 items-center transition-transform duration-300 ease-in-out hover:scale-110"
        >
          <FaReact className="text-blue-500" />
          <h1 className="text-xl mt-2 font-light">React</h1>
        </a>

        {/* Tailwind */}
        <a
          href="https://tailwindcss.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Tailwind CSS Website"
          className="flex flex-col mt-5 items-center transition-transform duration-300 ease-in-out hover:scale-110"
        >
          <SiTailwindcss className="text-teal-500" />
          <h1 className="text-xl mt-2 font-light">Tailwind</h1>
        </a>

        {/* Firebase */}
        <a
          href="https://firebase.google.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Firebase Website"
          className="flex flex-col mt-5 items-center transition-transform duration-300 ease-in-out hover:scale-110"
        >
          <SiFirebase className="text-yellow-500" />
          <h1 className="text-xl mt-2 font-light">Firebase</h1>
        </a>

        {/* GitHub */}
        <a
          href="https://github.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="GitHub Website"
          className="flex flex-col mt-5 items-center transition-transform duration-300 ease-in-out hover:scale-110"
        >
          <FaGithub className="text-black" />
          <h1 className="text-xl mt-2 font-light">GitHub</h1>
        </a>

        {/* Python */}
        <a
          href="https://www.python.org/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Python Website"
          className="flex flex-col mt-5 items-center transition-transform duration-300 ease-in-out hover:scale-110"
        >
          <FaPython className="text-black" />
          <h1 className="text-xl mt-2 font-light">Python</h1>
        </a>
      </div>
    );
  };

  return (
    <div className="w-full m-auto">
      <div className="border-b-2 w-full text-center py-4">
          <h1 className="text-4xl mb-5">{t("aboutProject.more")}</h1>
        </div>
      <CustomList content={supportUsContent} />

      {/* Support us Section */}
      <div className="support-widgets">
        <h2 className="text-center text-2xl my-8">
          {t("supportUs.widgetsHeader")} ❤️
        </h2>

        <div className="flex flex-wrap justify-center gap-8 mb-8">
          {/* Patreon Widget */}
          <a
            href="https://www.patreon.com/Palivoslovensko"
            target="_blank"
            rel="noopener noreferrer"
            className="widget-link hover:scale-105 transition-transform"
          >
            <img
              src="https://c5.patreon.com/external/logo/become_a_patron_button@2x.png"
              alt="Podporte nás na Patreone"
              className="h-12 w-auto"
            />
          </a>

          {/* Buy Me a Coffee Widget */}
          <a
            href="https://www.buymeacoffee.com/palivoslovt"
            target="_blank"
            rel="noopener noreferrer"
            className="widget-link hover:scale-105 transition-transform"
          >
            <img
              src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
              alt="Kúpte nám kávu"
              className="h-12 w-auto"
            />
          </a>
        </div>

        <p className="text-center font-bold text-gray-900 text-lg mx-auto">
          {t("supportUs.widgetsText")}
        </p>
      </div>
      <CustomList />

      <CustomList content={aboutProjectContent} CustomComponent={Icons} />
      <CustomList content={[furtherGoals]} />
    </div>
  );
}
