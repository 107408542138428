import React, { createContext, useContext, useEffect } from "react";
import { useYearsComparisonParams } from "../hooks/useYearsComparisonParams";
import { useYearsComparisonData } from "../hooks/useYearsComparisonData";

const YearsComparisionContext = createContext();

export const useYearsComparisionContext = () =>
  useContext(YearsComparisionContext);

export const YearsComparisionContextProvider = ({ children }) => {
  const { paramsState, setParamsState } = useYearsComparisonParams();
  const { apiData, data, loading, error, filterFuels } =
    useYearsComparisonData(paramsState);

  useEffect(() => {
    filterFuels(paramsState, apiData);
  }, [paramsState]);

  return (
    <YearsComparisionContext.Provider
      value={{
        apiData,
        data,
        loading,
        error,
        paramsState,
        setParamsState,
        filterFuels,
      }}
    >
      {children}
    </YearsComparisionContext.Provider>
  );
};
