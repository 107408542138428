import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStatisticsSectionContext } from "../../context/StatisticsSectionContext.jsx";
import { t } from "i18next";
import { getCurrentWeekNumber } from "../../utils/week-functions.js";
import { MAX_WEEK, MIN_WEEK, CURRENT_YEAR } from "../../utils/constants.js";
import Error from "../layout/Error";
import {
  sanitizeFuels,
  sanitizeWeek,
  sanitizeYear,
} from "../../utils/sanitize-functions.js";

const FuelFilter = () => {
  const { apiData, paramsState, setParamsState, error, filterFuels } =
    useStatisticsSectionContext();

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      year: paramsState.year,
      start: paramsState.start,
      end: paramsState.end,
      fuels: paramsState?.fuels,
    },
  });
  const [maxWeek, setMaxWeek] = useState(MAX_WEEK); // Default to MAX_WEEK

  useEffect(() => {
    const currentYear = watch("year");
    const calculatedMaxWeek = getCurrentWeekNumber(currentYear);

    setMaxWeek(calculatedMaxWeek);
  }, [watch("year")]);

  const onSubmit = (formData) => {
    if (formData.start > formData.end) {
      [formData.start, formData.end] = [formData.end, formData.start];
    }
    const sanatizedYear = sanitizeYear(formData.year);
    const updatedParams = {
      ...paramsState,
      year: sanatizedYear, //parseInt(formData.year, 10) || CURRENT_YEAR, // Fallback to current year
      start: sanitizeWeek(formData.start, sanatizedYear), //parseInt(formData.start, 10) || MIN_WEEK, // Fallback to MIN_WEEK
      end: sanitizeWeek(formData.end, sanatizedYear, true), //parseInt(formData.end, 10) || maxWeek, // Fallback to maxWeek
      fuels: sanitizeFuels(formData.fuels), //fuels.filter((fuel) => !isNaN(fuel)), // Filter out NaN fuels
    };

    setParamsState(updatedParams);
    filterFuels(updatedParams, apiData);
  };

  return (
    <div className="w-full max-w-full mx-auto">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full py-2">
        {/* Fuel Checkbox List */}
        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          <h2 className="text-lg font-semibold text-gray-700 mb-3">
            {t("common.selectFuel")}
          </h2>

          {/* Responsive Grid for Checkboxes */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3">
            {apiData.map((fuel, index) => (
              <label
                key={fuel.name}
                className="flex items-center justify-start gap-3 p-3 rounded-lg border border-gray-300 bg-white cursor-pointer transition-all duration-300 
                hover:bg-gray-200 peer-checked:bg-green-500 peer-checked:text-white"
              >
                <input
                  type="checkbox"
                  {...register("fuels")}
                  value={index}
                  defaultChecked={paramsState.fuels.includes(index)}
                />
                <span className="font-medium">{fuel.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Year Selection */}
        <div className="flex flex-col md:flex-row items-center gap-4 bg-gray-100 p-4 rounded-lg shadow-inner mt-4">
          <label className="text-gray-700 font-semibold text-lg">
            {t("common.selectYear")}
          </label>
          <input
            type="number"
            min="2000"
            max={CURRENT_YEAR}
            {...register("year", { required: true })}
            className="w-full sm:w-32 px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Week Interval Selection */}
        <div className="flex flex-col md:flex-row justify-between items-center bg-gray-100 p-4 rounded-lg shadow-inner mt-4">
          <label className="text-gray-700 font-semibold text-lg">
            {t("common.week")}
          </label>
          <div className="flex gap-4">
            <input
              id="start-week"
              type="number"
              min={MIN_WEEK}
              max={maxWeek}
              placeholder={paramsState.start}
              {...register("start", { required: true })}
              className="w-20 px-3 py-2 border border-gray-300 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <span className="text-gray-600">{t("filterData.until")}</span>
            <input
              id="end-week"
              type="number"
              min={MIN_WEEK}
              max={maxWeek}
              placeholder={paramsState.end}
              {...register("end", { required: true })}
              className="w-20 px-3 py-2 border border-gray-300 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-green-600 w-fit text-white px-6 py-4 text-2xl rounded-lg font-semibold shadow-md hover:bg-green-700 transition"
          >
            {t("common.show")}
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="text-red-500 font-medium bg-red-100 p-3 rounded-md shadow mt-3">
            <Error messageKey={t("common.notFound")} />
          </div>
        )}
      </form>
    </div>
  );
};

export default FuelFilter;
