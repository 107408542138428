import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

export const useModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const modalRef = useRef();
  const modalElementFromUrl = searchParams.get("zoomed") || null;
  const [modalElement, setModalElement] = useState(modalElementFromUrl);

  useEffect(() => {
    setModalElement(modalElementFromUrl);
  }, [modalElementFromUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (modalElement) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalElement]);

  const toggleModal = (id) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      if (modalElement === id) {
        newParams.delete("zoomed");
      } else {
        newParams.set("zoomed", id);
      }
      return newParams;
    });
  };

  const closeModal = () => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete("zoomed");
      return newParams;
    });
  };

  return { modalElement, toggleModal, modalRef };
};
