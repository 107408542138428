import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  processDate,
  getObjectWithMaxMeasures,
} from "../../utils/week-functions.js";
import { useTranslation } from "react-i18next";
import { CURRENCY } from "../../utils/constants.js";
import { graphColors } from "../../utils/colors.js";
import { useResponsiveFont } from "../../hooks/useResponsiveFont.jsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function LineGraph({ data, specialLabels, customTitle }) {
  const { t } = useTranslation();
  const baseFontSize = useResponsiveFont();

  const getLabel = (fuel, index) =>
    specialLabels ? specialLabels[index] : fuel.name;

  const graphData = useMemo(() => {
    const largestDataObject = getObjectWithMaxMeasures(data);
    return {
      labels: largestDataObject?.measuresArray?.map((el) =>
        processDate(el.week)
      ),
      datasets: data?.map((fuel, index) => ({
        label: getLabel(fuel, index),
        data: fuel.measuresArray?.map((el) => el.value),
        borderColor: graphColors[index]?.borderColor,
        backgroundColor: graphColors[index]?.backgroundColor,
        pointRadius: 4,
        pointHoverRadius: 6,
      })),
    };
  }, [data]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: { size: baseFontSize - 2 },
            overflow: "auto",
          },
        },
        title: {
          display: true,
          text: customTitle,
          font: { size: baseFontSize + 2 },
          position: "top",
          padding: { top: 0, bottom: 10 },
        },
        tooltip: {
          position: "nearest",
          callbacks: {
            label: (tooltipItem) =>
              `${t("common.price")}: ${tooltipItem.raw}${CURRENCY}`,
          },
          padding: 0,
        },
        datalabels: {
          display: false,
        },
      },
      layout: { padding: { top: 0 } },
      scales: {
        x: {
          title: {
            display: true,
            text: t("common.week"),
            font: { size: baseFontSize },
          },
          ticks: {
            autoSkip: true,
            maxRotation: 45,
            minRotation: 0,
            font: { size: baseFontSize - 2 },
          },
        },
        y: {
          title: {
            display: true,
            text: `${t("common.price")} (${CURRENCY})`,
            font: { size: baseFontSize },
            padding: 0,
          },
          ticks: {
            font: { size: baseFontSize - 2 },
          },
          beginAtZero: true,
        },
      },
    };
  }, [baseFontSize, customTitle, t, data]);

  return (
    <div className="max-w-full min-w-full max-h-full min-h-full py-10 overflow-auto">
      <Line data={graphData} options={options} />
    </div>
  );
}
