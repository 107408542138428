// useResponsiveFont.js
import { useState, useEffect } from "react";

export const useResponsiveFont = () => {
  // For example, if window width < 768, use 12px; else 16px.
  const calculateFontSize = () => (window.innerWidth < 768 ? 12 : 16);
  const [baseFontSize, setBaseFontSize] = useState(calculateFontSize());

  useEffect(() => {
    const handleResize = () => setBaseFontSize(calculateFontSize());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return baseFontSize;
};
