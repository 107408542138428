import { CACHE_URL, API_URL } from "./constants.js";
import {
  getCurrentWeekNumber,
  createWeekRequestString,
} from "./week-functions.js";
import { processApiData } from "./filter-functions.js";
export const fetchAllFuelsFromApi = async (year, lang = "sk") => {
  const currentWeekStr = getCurrentWeekNumber(year).toString();
  const yearStr = year.toString();

  const weekRequestStr = createWeekRequestString(
    yearStr + currentWeekStr,
    `${yearStr}01`
  );

  let response;
  try {

    response = await fetch(`${CACHE_URL}${weekRequestStr}/${lang}`);
    if (response.status !== 200) throw new Error("Cache fail");
  } catch (error) {

    response = await fetch(
      `${API_URL}${weekRequestStr}/all?lang=${lang}&type=json`
    );
  }
  const result = await response.json();
  return processApiData(result);
};

export const getDataFromCacheByCurrentDate = (
  savedDataKey,
  currentDate,
  lang
) => {
  const savedData = localStorage.getItem(savedDataKey);
  const savedDate = localStorage.getItem(`currentDate${lang}`);

  if (savedData && savedDate === JSON.stringify(currentDate)) {
    const parsedData = JSON.parse(savedData);
    if (parsedData?.length > 0) {
      console.log("Data loaded from local storage");
      return { apiData: parsedData, data: parsedData, error: null };
    }
  }
  return { apiData: [], data: [], error: new Error("No data") };
};

export const getDataFromCacheByYear = (savedDataKey) => {
  const savedData = localStorage.getItem(savedDataKey);
  const result = {
    apiData: [],
    data: [],
    error: null,
  };
  if (savedData) {
    const parsedData = JSON.parse(savedData);
    if (parsedData?.length !== 0 || parsedData) {
      result.apiData = parsedData;
      result.data = parsedData;
      console.log("Data are from local storage");
      return result;
    }
  }
  result.error = new Error("No data");
  return result;
};
