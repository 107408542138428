import { MAX_WEEK, MIN_WEEK, EMPTY_VALUE, EMPTY_WEEK } from "./constants";

export const createWeekRequestString = (start, end) => {
  if (start === end) return start;
  let startNum = parseInt(start, 10);
  let endNum = parseInt(end, 10);
  if (startNum < endNum) {
    let tmp = startNum;
    startNum = endNum;
    endNum = tmp;
  }
  const numbers = [];

  for (let i = startNum; i >= endNum; i--) {
    numbers.push(i);
  }
  return numbers.join(",");
};

export const getCurrentWeekNumber = (year) => {
  const currentDate = new Date();
  if (year < currentDate.getFullYear()) {
    return MAX_WEEK;
  }

  const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  const daysToNextMonday =
    januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
  const nextMonday = new Date(
    currentDate.getFullYear(),
    0,
    januaryFirst.getDate() + daysToNextMonday
  );

  const weekNumber =
    currentDate < nextMonday
      ? MAX_WEEK
      : currentDate > nextMonday
      ? Math.ceil((currentDate - nextMonday) / (24 * 3600 * 1000) / 7)
      : MIN_WEEK;

  return weekNumber.toString().padStart(2, "0");
};

export const getWeekIntervalStr = (firstWeek, lastWeek) => {
  return `${firstWeek?.split("-")[0].split("(")[1]}- ${lastWeek
    ?.split("-")[1]
    ?.replace(")", "")}`.replaceAll(" ", "");
};

export const getFirstAndLastWeeks = (measuresArray) => {
  const parsedWeeks = measuresArray.filter(
    (measure) => measure.value !== EMPTY_VALUE
  );
  if (parsedWeeks.length === 0) {
    return {
      firstWeek: { value: EMPTY_VALUE, week: EMPTY_WEEK },
      lastWeek: { value: EMPTY_VALUE, week: EMPTY_WEEK },
    };
  }
  const sortedWeeks = parsedWeeks.sort((a, b) => a.weekNumber - b.weekNumber);
  const firstWeek = sortedWeeks[0];
  const lastWeek = sortedWeeks[sortedWeeks.length - 1];

  return { firstWeek, lastWeek };
};

export const processDate = (date) => {
  return date.split(".").at(0);
};

export const getObjectWithMaxMeasures = (data) => {
  if (!Array.isArray(data) || data.length === 0) return null;

  return data.reduce(
    (maxItem, currentItem) =>
      currentItem.measuresArray?.length > (maxItem?.measuresArray?.length || 0)
        ? currentItem
        : maxItem,
    null
  );
};
