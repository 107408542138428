import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchAllFuelsFromApi,
  getDataFromCacheByCurrentDate,
} from "../utils/api-functions.js";
import {
  filterFuelsByType,
  filterMeasuresByInterval,
} from "../utils/filter-functions.js";

export const useStatisticsData = (paramsState) => {
  const { i18n } = useTranslation();

  const [apiData, setApiData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateData = (newApiData, newError = null) => {
    setError(newError);
    setApiData(newApiData);
    filterFuels(paramsState, newApiData);
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentHour = new Date().getHours();
      const currentDate = `${currentHour}/${new Date().toLocaleDateString()}${i18n.language}`;
      const savedDataKey = `${paramsState.year}${i18n.language}`;
      const cachedData = getDataFromCacheByCurrentDate(
        savedDataKey,
        currentDate,
        i18n.language
      );

      if (!cachedData.error && cachedData.apiData.length > 0) {
        updateData(cachedData.apiData);
        setLoading(false);
        return;
      }

      try {
        const processedData = await fetchAllFuelsFromApi(
          paramsState.year,
          i18n.language
        );
        if (!processedData) {
          updateData([], new Error("No data found."));
          setLoading(false);
          return;
        }

        updateData(processedData);
        localStorage.setItem(savedDataKey, JSON.stringify(processedData));
        localStorage.setItem(
          `currentDate${i18n.language}`,
          JSON.stringify(currentDate)
        );
      } catch (error) {
        updateData([], new Error("Error fetching data."));
      }

      setLoading(false);
    };

    fetchData();
  }, [paramsState.year, i18n.language]);

  const filterFuels = (newParamsState, newApiData) => {
    if(newParamsState.end < newParamsState.start){
      [newParamsState.end, newParamsState.start] = [newParamsState.start, newParamsState.end];
    }
    const filteredFuels = filterFuelsByType(newParamsState.fuels, newApiData);
    const filteredFuelsByWeek = filterMeasuresByInterval(
      newParamsState.start,
      newParamsState.end,
      filteredFuels
    );
    setData(filteredFuelsByWeek);
  };

  return { apiData, data, loading, error, filterFuels };
};
