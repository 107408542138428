import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { CiMinimize1, CiMaximize1 } from "react-icons/ci";
import PriceDevelopmentGraph from "../graphs/PriceDevelopmentGraph";
import YearlyPricingTable from "../graphs/YearlyPricingTable";
import RecordsTable from "../graphs/RecordsTable";
import CurrentWeek from "../graphs/CurrentWeek";
import BarGraph from "../graphs/BarGraph";
import { useStatisticsSectionContext } from "../../context/StatisticsSectionContext";
import FuelFilter from "../graphs/FuelFilter";
import Spinner from "../layout/Spinner";
import { getWeekIntervalStr } from "../../utils/week-functions";
import { useModal } from "../../hooks/useModal";
import InformationAboutData from "../graphs/InformationAboutData";
import AveragePricesTable from "../graphs/AveragePricesTable";

export default function Statistics() {
  const { t } = useTranslation();
  const { error, paramsState, loading, data } = useStatisticsSectionContext();
  const headerStyle = "font-bold text-lg tracking-wide";
  const { modalElement, modalRef, toggleModal } = useModal();

  useEffect(() => {
    toggleModal(paramsState.zoomed);
  }, []);

  const modalClasses = (id) =>
    modalElement === id
      ? "fixed inset-0 z-50 bg-white shadow-lg p-4 overflow-y-auto min-h-[100vh] max-h-[100vh]"
      : "rounded-lg shadow-md overflow-hidden";

  const components = [
    {
      id: "current-week",
      title: t("statistics.comparision"),
      style: "col-span-1 md:col-span-3",
      element: <CurrentWeek />,
    },
    {
      id: "fuel-selection-heading",
      title: t("statistics.checkboxHeader"),
      style: "max-h-fit overflow-y-auto col-span-1 md:col-span-3",
      element: <FuelFilter />,
    },
    {
      id: "average-prices",
      title: t("statistics.averagePrices"),
      style: "max-h-fit overflow-y-auto col-span-1 md:col-span-3",
      element: <AveragePricesTable />,
    },
    {
      id: "max-records",
      title: t("statistics.maxRecords"),
      style: "max-h-[50vh] overflow-y-auto", // Ensure scrollbar for overflow
      element: <RecordsTable type="max" />,
    },
    {
      id: "min-records",
      title: t("statistics.minRecords"),
      style: "max-h-[50vh] overflow-y-auto", // Ensure scrollbar for overflow
      element: <RecordsTable type="min" />,
    },

    {
      id: "price-development-graph",
      title: t("statistics.priceDevelopmentGraph"),
      style: "col-span-1 md:col-span-3 max-h-[80vh]",
      element: (
        <PriceDevelopmentGraph
          data={data}
          paramsState={paramsState}
          customTitle={`${t("statistics.lineGraphLabel")} ${paramsState.year}`}
        />
      ),
    },
    {
      id: "bar-graph",
      title: t("statistics.barGraph"),
      style: "col-span-1 md:col-span-3 max-h-[80vh]",
      element: (
        <BarGraph
          data={data}
          paramsState={paramsState}
          customTitle={`${t("statistics.barGraphLabel")} ${paramsState.year}`}
        />
      ),
    },
    {
      id: "yearly-pricing-table",
      title: t("statistics.yearlyPricingTable"),
      style: "col-span-1 md:col-span-3 max-h-[70vh] overflow-auto",
      element: <YearlyPricingTable />,
    },
    {
      id: "informations",
      title: t("informationAboutData.title"),
      style: "col-span-1 md:col-span-3  max-[50vh] overflow-y-auto ",
      element: <InformationAboutData />,
    },
  ];
  let numberOfFuels, measuresArray, firstWeek, lastWeek, date;
  if (!loading && data[0]) {
    numberOfFuels = data[0]?.measuresArray.length;
    measuresArray = data[0]?.measuresArray;

    firstWeek = measuresArray[0]?.week;
    lastWeek = measuresArray[numberOfFuels - 1]?.week;

    date = getWeekIntervalStr(firstWeek, lastWeek);
  }
  return (
    <>
      <title>Vitajte na PalivoSlovensko.sk | Údaje o benzíne a nafte</title>
      <meta
        name="description"
        content="Vaša komplexná platforma pre ceny palív a štatistiky na Slovensku. Preskúmajte trendy, prehľady a historické údaje o benzíne a nafte."
      />
      <div className="flex flex-col ">
        <div className="max-w-3xl mx-auto text-center mt-12">
          <h1 className="text-6xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            {t("statistics.header")}
          </h1>
          {!!date ? <h2 className="text-2xl font-bold">{date}</h2> : ""}
        </div>
      </div>
      <main className="m-2 gap-4 grid grid-cols-1 md:grid-cols-2">
        {components.map(({ id, title, element, style }) => {
          if (error && id !== "fuel-selection-heading") {
            return ""; //
          }
          return (
            <div
              key={id}
              className={`${modalClasses(id)} ${style} `}
              ref={modalElement === id ? modalRef : null}
              aria-labelledby={`${id}-heading`}
            >
              <header className="sticky top-0 bg-neutral-100 flex justify-between items-center">
                <h2
                  className={`${headerStyle} text-base md:text-lg`}
                  id={`${id}-heading`}
                >
                  {title}
                </h2>
                {modalElement === id ? (
                  <CiMinimize1
                    className="cursor-pointer text-4xl mx-3"
                    onClick={() => toggleModal(id)}
                  />
                ) : (
                  <CiMaximize1
                    className="cursor-pointer text-2xl mx-3 "
                    onClick={() => toggleModal(id)}
                  />
                )}
              </header>
              {loading ? <Spinner /> : element}
            </div>
          );
        })}
      </main>

      <footer id="source" className="m-4" aria-labelledby="data-source-heading">
        <p>
          {t("sections.dataSource")}
          <a
            id="data-source"
            className="font-bold"
            href="http://www.statistics.sk/"
          >
            http://www.statistics.sk/
          </a>
        </p>
      </footer>
    </>
  );
}
