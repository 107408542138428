import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [lang, setLangState] = useState(i18n.language || "sk");

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  const setLang = (newLang) => {
    setLangState(newLang);
  };

  return {
    lang,
    setLang,
  };
};
