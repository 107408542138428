import React, { useMemo } from "react";
import { CURRENCY, MAX_WEEKS_TO_DISPLAY_PRICE } from "../../utils/constants.js";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  processDate,
  getObjectWithMaxMeasures,
} from "../../utils/week-functions.js";
import { useTranslation } from "react-i18next";
import { graphColors } from "../../utils/colors.js";
import { useResponsiveFont } from "../../hooks/useResponsiveFont.jsx";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function BarGraph({ data, specialLabels, customTitle }) {
  const { t } = useTranslation();
  const baseFontSize = useResponsiveFont();
  const getLabel = (fuel, index) => {
    if (specialLabels) return specialLabels[index];
    return fuel.name;
  };

  const graphData = useMemo(() => {
    const largestDataObject = getObjectWithMaxMeasures(data);
    return {
      labels: largestDataObject?.measuresArray?.map((el) =>
        processDate(el.week)
      ),
      datasets: data?.map((fuel, index) => ({
        label: getLabel(fuel, index),
        data: fuel.measuresArray?.map((el) => el.value),
        backgroundColor: graphColors[index]?.backgroundColor,
        borderColor: graphColors[index]?.borderColor,
        borderWidth: 1,
      })),
    };
  }, [data]);

  const options = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: { size: baseFontSize - 2 },
            overflow: "auto",
          },
        },
        title: {
          display: true,
          text: customTitle,
          font: { size: baseFontSize + 2 },
          position: "top",
          padding: { top: 0, bottom: 10 },
        },
        tooltip: {
          position: "nearest",
          callbacks: {
            label: (tooltipItem) =>
              `${t("common.price")}: ${tooltipItem.raw}${CURRENCY}`,
          },
          padding: 0,
        },
        datalabels: {
          display: true,
          color: "gray",
          anchor: "end",
          align: "end",
          //   rotation: -60,
          font: { size: baseFontSize - 2 },
          padding: 0,
          formatter: (value) => {
            if (data[0]?.measuresArray?.length > MAX_WEEKS_TO_DISPLAY_PRICE) {
              return "";
            }
            return `${value.toFixed(2)}`;
          },
        },
      },
      layout: { padding: { top: 0 } },
      scales: {
        x: {
          title: {
            display: true,
            text: t("common.week"),
            font: { size: baseFontSize },
          },
          ticks: {
            autoSkip: true,
            maxRotation: 45,
            minRotation: 0,
            font: { size: baseFontSize - 2 },
          },
        },
        y: {
          title: {
            display: true,
            text: `${t("common.price")} (${CURRENCY})`,
            font: { size: baseFontSize },
            padding: 0,
          },
          ticks: {
            font: { size: baseFontSize - 2 },
          },
          beginAtZero: true,
        },
      },
    };
  }, [baseFontSize, customTitle, t, data]);

  return (
    <div className="max-w-full min-w-full max-h-full min-h-full py-10 overflow-auto">
      <Bar data={graphData} options={options} />
    </div>
  );
}
