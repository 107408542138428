import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  fetchAllFuelsFromApi,
  getDataFromCacheByYear,
} from "../utils/api-functions.js";

export const useYearsComparisonData = (paramsState) => {
  const { i18n } = useTranslation();

  const [apiData, setApiData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const updateData = (newApiData, error) => {
    setError(error);
    setApiData(newApiData);
    filterFuels(paramsState, newApiData);
  };

  const filterFuels = (newParamsState, newApiData) => {
    const filteredFirstFuelsByType =
      newApiData.firstYearApiData?.[newParamsState.fuel] || [];
    const filteredSecondFuelsByType =
      newApiData.secondYearApiData?.[newParamsState.fuel] || [];

    setData([filteredFirstFuelsByType, filteredSecondFuelsByType]);
  };

  useEffect(() => {
    const fetchYearData = async (year, savedDataKey, cachedData) => {
      if (cachedData.error || cachedData.apiData.length === 0) {
        const processedData = await fetchAllFuelsFromApi(year, i18n.language);
        localStorage.setItem(savedDataKey, JSON.stringify(processedData));
        return processedData;
      }
      return cachedData.apiData;
    };

    const fetchData = async () => {
      setLoading(true);

      const savedFirstDataKey = `${paramsState.firstYear}${i18n.language}`;
      const savedSecondDataKey = `${paramsState.secondYear}${i18n.language}`;

      const cachedFirstYearData = getDataFromCacheByYear(savedFirstDataKey);
      const cachedSecondYearData = getDataFromCacheByYear(savedSecondDataKey);

      try {
        const [firstYearApiData, secondYearApiData] = await Promise.all([
          fetchYearData(paramsState.firstYear, savedFirstDataKey, cachedFirstYearData),
          fetchYearData(paramsState.secondYear, savedSecondDataKey, cachedSecondYearData),
        ]);

        if (firstYearApiData.length > 0 || secondYearApiData.length > 0) {
          updateData({ firstYearApiData, secondYearApiData });
        } else {
          updateData({}, new Error("No data found."));
        }
      } catch (error) {
        updateData({}, new Error("Error fetching data."));
      }

      setLoading(false);
    };

    fetchData();
  }, [i18n.language, paramsState.firstYear, paramsState.secondYear]);

  return { apiData, data, loading, error, filterFuels };
};
