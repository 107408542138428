import React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../public/logo.png";
import { useLanguage } from "../../hooks/useLanguage";

export default function NavigationBar() {
  const { t, i18n } = useTranslation();
  const { setLang } = useLanguage();
  const navigation = [
    { name: t("navigation.statistics"), href: "statistiky" },
    { name: t("navigation.aboutProject"), href: "oprojekte" },
    { name: t("navigation.compareYears"), href: "rocneporovnanie" },
  ];

  function getFlagEmoji(countryCode) {
    if (countryCode === "en") {
      countryCode = "GB";
    }
    const codePoints = countryCode
      .toUpperCase()
      .split("")
      ?.map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  }

  function toggleLanguage() {
    const newLanguage = i18n.language === "sk" ? "en" : "sk";
    setLang(newLanguage);
    //i18n.changeLanguage(newLanguage);
  }

  return (
    <Disclosure as="nav" className="bg-[#F6F6F6] w-full text-black shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          {/* Mobile Menu Button */}
          <div className="flex sm:hidden">
            <DisclosureButton className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              <XMarkIcon className="hidden h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          {/* Logo and Title */}
          <a
            href="/"
            className="flex items-center space-x-2 group hover:translate-x-1 transition-all duration-300"
          >
            <img
              src={logo}
              alt="PalivoSlovensko"
              className="h-12 w-auto transform transition-transform duration-300 group-hover:rotate-12"
            />
            <h1 className="font-bold text-lg truncate overflow-hidden">
              Palivoslovensko.sk
            </h1>
          </a>

          {/* Navigation Links - Desktop */}
          <div className="hidden sm:flex flex-1 justify-end space-x-4 items-center overflow-auto">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) =>
                  `px-3 py-2 text-lg truncate transition-all duration-300 ease-in-out 
                hover:shadow-md hover:scale-[1.02] 
                ${
                  isActive
                    ? "border-b-2 border-gray-400 text-gray-900 font-medium"
                    : "text-gray-900"
                }`
                }
              >
                {item.name}
              </NavLink>
            ))}

            {/* Language Toggle */}
            <button
              onClick={toggleLanguage}
              className="px-3 py-2 text-lg flex items-center hover:shadow-xl hover:scale-[1.020] transition-all duration-300 ease-in-out"
            >
              <span className="truncate">{t("navigation.language")}</span>
              <span className="px-1">{getFlagEmoji(i18n.language)}</span>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Panel */}
      <DisclosurePanel className="sm:hidden">
        <div className="flex flex-col space-y-2 px-4 pb-3 pt-2">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className="block px-3 py-2 rounded-md text-lg hover:bg-gray-200"
            >
              {item.name}
            </NavLink>
          ))}

          {/* Language Toggle (Mobile) */}
          <button
            onClick={toggleLanguage}
            className="block w-full px-3 py-2 rounded-md text-lg hover:bg-gray-200 flex items-center"
          >
            <span>{t("navigation.language")}</span>
            <span className="px-2">{getFlagEmoji(i18n.language)}</span>
          </button>
        </div>
      </DisclosurePanel>
    </Disclosure>
  );
}
