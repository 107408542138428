import React from "react";
import NavigationBar from "./components/layout/NavigationBar";
import FooterSection from "./components/layout/FooterSection";
import { Routes, Route } from "react-router-dom";
import "./i18n";
import Statistics from "./components/pages/Statistics";
import AboutProject from "./components/pages/AboutProject";
import RouteNotFound from "./components/layout/RouteNotFound";
import LandingPage from "./components/pages/LandingPage";
import YearsComparision from "./components/pages/YearsComparision";
import { StatisticsSectionProvider } from "./context/StatisticsSectionContext";
import { YearsComparisionContextProvider } from "./context/YearsComparisionContext";
import MainSection from "./components/layout/MainSection";
import { useLanguage } from "./hooks/useLanguage";


function App() {
  const {lang} = useLanguage();
  return (
    <div className="App">
      <NavigationBar />

      <Routes>
        {/* Landing Page Route */}
        <Route path="/" element={<LandingPage />} />

        {/* Main Section Routes */}
        <Route element={<MainSection />}>
          <Route
            path="/statistiky"
            element={
              <StatisticsSectionProvider>
                <Statistics />
              </StatisticsSectionProvider>
            }
          />
          <Route path="/oprojekte" element={<AboutProject />} />
          <Route
            path="/rocneporovnanie"
            element={
              <YearsComparisionContextProvider>
                <YearsComparision />
              </YearsComparisionContextProvider>
            }
          />
        </Route>

        {/* Fallback Route */}
        <Route path="*" element={<RouteNotFound />} />
      </Routes>

      <FooterSection />
    </div>
  );
}

export default App;
