import React from "react";
import { CURRENCY, EMPTY_VALUE, EMPTY_WEEK } from "../../utils/constants.js";
import { useStatisticsSectionContext } from "../../context/StatisticsSectionContext";
import { useTranslation } from "react-i18next";
import { getFuelWithMaxOrMinValue } from "../../utils/filter-functions.js";

export default function RecordsTable({ type }) {
  const { data } = useStatisticsSectionContext();
  const { t } = useTranslation();

  const processedData = getFuelWithMaxOrMinValue(data, type);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse table-auto">
        <thead className="bg-gray-200 text-gray-700">
          <tr>
            <th className="px-4 py-2 text-left">{t("common.fuelName")}</th>
            <th className="px-4 py-2 text-left">{t("common.price")}</th>
            <th className="px-4 py-2 text-left">{t("common.week")}</th>
          </tr>
        </thead>
        <tbody>
          {processedData?.map((fuel, index) => (
            <tr
              key={index}
              className={`border-t transition duration-150 ease-in-out hover:bg-gray-100 hover:text-black ${
                index % 2 === 0 ? "bg-gray-50" : "bg-white"
              }`}
            >
              <td className="px-4 py-2" key={`name-${index}`}>
                {fuel.name}
              </td>
              <td className="px-4 py-2" key={`value-${index}`}>
                {fuel.value !== null ? fuel.value + CURRENCY : EMPTY_VALUE}
              </td>
              <td className="px-4 py-2" key={`week-${index}`}>
                {fuel.week !== null ? fuel.week : EMPTY_WEEK}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
