import React, { createContext, useContext, useEffect } from "react";
import { useStatisticsParams } from "../hooks/useStatisticsParams";
import { useStatisticsData } from "../hooks/useStatisticsData";

const StatisticsSectionContext = createContext();

export const useStatisticsSectionContext = () =>
  useContext(StatisticsSectionContext);

export const StatisticsSectionProvider = ({ children }) => {
  const { paramsState, setParamsState } = useStatisticsParams();
  const { apiData, data, loading, error, filterFuels } =
    useStatisticsData(paramsState);

  useEffect(() => {
    if (apiData?.length === 0) return;
    filterFuels(paramsState, apiData);
  }, []);

  return (
    <StatisticsSectionContext.Provider
      value={{
        apiData,
        data,
        loading,
        error,
        paramsState,
        setParamsState,
        filterFuels,
      }}
    >
      {children}
    </StatisticsSectionContext.Provider>
  );
};
